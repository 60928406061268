import React, { useEffect } from 'react'
import { useFlag, useFlagsStatus } from '@unleash/nextjs'
import { Connector, WagmiConfig, configureChains, createConfig } from 'wagmi'
import {
  arbitrum,
  bsc,
  bscTestnet,
  polygon,
  polygonMumbai,
  sepolia,
  mainnet,
  optimism,
  base,
  baseSepolia,
  kroma,
  kromaSepolia,
} from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'

import { RoninConnector, ronin, saigon } from 'ronin-connector'
import { Coin98Connector } from '@/connector/coin98-connector'
import { Ancient8Connector } from '@/connector/a8-connector'

import { arbitrumSepolia } from '@/configs/wagmi/chain-expansion/arbitrum-sepolia.config'
import { ancient8Testnet } from '@/configs/wagmi/chain-expansion/ancient8-testnet.config'
import { ancient8CelestiaTestnet } from '@/configs/wagmi/chain-expansion/accient8-celestia-testnet.config'
import { ancient8 } from '@/configs/wagmi/chain-expansion/ancient8.config'
import { FeatureFlag } from '@/constants/feature-flags'
import { mint } from '@/configs/wagmi/chain-expansion/mint.config'
import { mintSepoliaTestnet } from '@/configs/wagmi/chain-expansion/mint-sepolia-testnet'

const a8Chains = [ancient8Testnet, ancient8CelestiaTestnet, ancient8]

const evmChains = [
  ...a8Chains,
  bsc,
  bscTestnet,
  polygon,
  polygonMumbai,
  arbitrum,
  arbitrumSepolia,
  optimism,
  mainnet,
  sepolia,
  base,
  baseSepolia,
  kroma,
  kromaSepolia,
  mint,
  mintSepoliaTestnet,
]

const roninChains = [ronin, saigon]

const { publicClient } = configureChains(
  [...evmChains, ...roninChains],
  [publicProvider()],
)

const connectorsConfig: Connector[] = [
  new MetaMaskConnector({ chains: evmChains }),
  new RoninConnector({
    chains: roninChains,
    options: { projectId: '' },
  }),
  new Coin98Connector({ chains: evmChains }),
  new CoinbaseWalletConnector({
    chains: evmChains,
    options: {
      appName: 'a8-id',
    },
  }),
]

const createCustomStorage = () => ({
  getItem: (key: string, defaultState?: any) => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(key) ?? defaultState
    }
    return null
  },
  setItem: (key: string, value: any) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, value)
    }
  },
  removeItem: (key: string) => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(key)
    }
  },
})

const config = createConfig({
  autoConnect: true,
  publicClient,
  connectors: connectorsConfig,
  storage: createCustomStorage(),
})

const WagmiProvider = ({ children }: { children: React.ReactNode }) => {
  const isFeatureEnabled = useFlag(FeatureFlag.A8Wallet)
  const { flagsReady } = useFlagsStatus()

  useEffect(() => {
    if (!flagsReady) return

    const connectors = [...connectorsConfig]
    if (isFeatureEnabled) {
      connectors.push(
        new Ancient8Connector({
          chains: a8Chains,
        }),
      )
    }

    config.setConnectors(connectors)
  }, [flagsReady, isFeatureEnabled])

  return <WagmiConfig config={config}>{children}</WagmiConfig>
}

export default WagmiProvider
